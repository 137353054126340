#help-section {
  div[slot] {
    margin-top: 16px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .topics-overview-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    a {
      display: block;
    }
  }

  .topic-section {
    display: inline-block;
    margin-bottom: 4rem;

    > div.update-info {
      margin-bottom: 12px;
    }

    > div:last-child {
      margin-left: 1.6rem;
    }

    .subtopic {
      white-space: nowrap;
      margin-bottom: 8px;
    }
  }

  .title {
    font-weight: bold;
  }

  .help-desk-box {
    display: inline-flex;
    flex-direction: column;
    margin-top: 16px;
    padding: 32px;
    background-color: white;
    border: 1px solid #d8d8d8;
    border-radius: 6px;

    mc-button {
      margin-top: 16px;
      align-self: flex-end;
    }
  }

  margin-bottom: 64px;
}

// react-to-print requires printed content to be targeted directly and not from unprinted parent
#help-section-subtopic {
  table + h4,
  img + h4 {
    margin-top: 1.6rem;
  }

  ul {
    line-height: 1.5;
    list-style-position: inside;
    list-style-type: disc;
    margin: 1.6rem 0;
  }

  img {
    width: 50rem;
  }

  table {
    th {
      font-weight: bold;
    }

    td,
    th {
      border: 1px solid #dbdbdb;
      padding: 1.2rem;
      text-align: left;
    }

    .centered {
      text-align: center;

      mc-icon {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  .flex-container,
  .image-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1.6rem;
  }

  .note {
    font-style: italic;
  }

  .title {
    font-weight: bold;
  }

  @media print {
    font-size: 1.2rem;

    .image-container,
    img {
      display: block;
      margin-bottom: 1.6rem;
    }
  }
}
