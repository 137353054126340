@use '~@maersk-global/fonts/maeu/fonts';
@use "~@maersk-global/mds-design-tokens/maersk/light/css/design-tokens-px";
@use '~@maersk-global/mds-foundations/css/foundations';

body {
  margin: 0;
  background-color: rgb(247, 247, 247) !important;
}

$maerskBlue500: #64b2d4;

input,
button {
  border: none;
}

* {
  &:focus {
    outline-color: $maerskBlue500;
  }
}

textarea {
  resize: none;
}

.Select.is-focused {
  .Select-control {
    outline-offset: -2px;
    outline: $maerskBlue500 auto 5px;
  }
}

select {
  appearance: none;
}

.react-datepicker {
  font-family: 'Maersk Text', serif;

  .react-datepicker__header {
    background-color: #fff;

    .react-datepicker__current-month {
      font-weight: normal;
    }
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 2rem;
    line-height: 2.7rem;
  }
}

.react-datepicker-popper {
  z-index: 100001 !important;
}

.Select-control {
  cursor: pointer;
}

.scroll-lock {
  overflow: hidden;
}

mc-modal {
  form {
    margin-bottom: 29px;
  }
}

mc-icon.mc-icon--warning::part(icon) {
  fill: #ffd029;
}

.mc-toast-container {
  z-index: 100000;
}
